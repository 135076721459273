<template>
  <div class="con-order">
    <h3>我的订单，共{{ orderList.length }}个</h3>
    <div class="order-box">
      <div class="order-item" v-for="order in orderList" :key="order.id">
        <div class="item-img">
          <img :src="order.cover_img" :alt="order.title" />
        </div>
        <div class="item-title">
          <span>{{ order.title }}</span>
        </div>
        <div class="item-price">￥{{ order.price }}</div>
      </div>
    </div>
    <div class="submit-button">
      <p>
        共计：<span>￥{{ totalPride }}</span>
      </p>
      <button @click="pay">支付</button>
    </div>

    <el-dialog
      title="使用微信扫码支付"
      :visible.sync="dialogVisible"
      width="20%"
      custom-class="wx-code"
      @closed="closeWxCode"
    >
      <div class="code-img" ref="qrcode"></div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      orderList: [],
      totalPride: 0,
      code: null,
      dialogVisible: false,
      interval: null,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getPays();
  },
  methods: {
    getPays() {
      const { oids, type } = this.$route.query;
      let fd = new FormData();
      fd.append("id", oids);
      fd.append("type", type ? type : 1);
      this.axios.post("/course/pays/payment", fd).then((res) => {
        console.log(res);
        this.orderList = res.data.data;
        this.orderList.map((item) => {
          this.totalPride += +item.price;
        });
        this.totalPride = this.totalPride.toFixed(2);
      });
    },
    async pay() {
      const user_id = JSON.parse(this.$store.state.cloudPc).user_id;

      let ids = [];
      this.orderList.forEach((ele) => {
        ids.push(ele.id);
      });
      let fd = new FormData();
      fd.append("id", ids.join(","));
      fd.append("type", this.$route.query.type ? this.$route.query.type : 1);
      fd.append("code", 1);
      fd.append("shop_price", this.totalPride);
      fd.append("user_id", user_id);
      let data = await this.axios.post("/coursepc/pays/pay", fd);
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.createQrcode(data.data.code_url);
      });
      this.selectOrder(data.data.no);
    },
    createQrcode(url) {
      this.$refs.qrcode.innerHTML = "";
      this.code = new QRCode(this.$refs.qrcode, {
        text: url, // 需要转换为二维码的内容
        width: 250,
        height: 250,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      }); // 设置要生成二维码的链接
    },
    selectOrder(noid) {
      this.interval = setInterval(async () => {
        let fd = new FormData();
        fd.append("number", noid);
        let { data } = await this.axios.post("/coursepc/wxpay/selectorder", fd);
        if (data.trade_state === "SUCCESS") {
          clearInterval(this.interval);
          this.$message.success("支付成功!");
          this.closeWxCode();
          this.paySuccess();
        }
      }, 2000);
    },
    closeWxCode() {
      this.dialogVisible = false;
      clearInterval(this.interval);
    },
    paySuccess() {
      const length = this.$route.query.oids.split(",").length;
      const { type } = this.$route.query;
      setTimeout(() => {
        if (length > 1 || type) {
          this.$router.replace("/personal");
        } else {
          const li = this.orderList[0];
          this.$router.replace(`/courdetails?cid=${li.id}&ct=${li.title}`);
        }
      }, 1000);
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>
<style lang="scss" scoped>
.con-order {
  width: 1200px;
  // height: 80vh;
  background-color: #fafafa;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  box-sizing: border-box;
  h3 {
    margin: 0;
    font-size: 23px;
  }
  .order-box {
    width: 100%;
    background-color: #fff;

    margin: 0 auto;
    margin-top: 30px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;

    .order-item {
      display: flex;
      padding: 20px;
      box-sizing: border-box;
      align-items: center;
      border-bottom: 1px solid #e8e8e8;
      .item-img {
        width: 30%;
        height: 160px;
        display: flex;
        align-items: center;
        img {
          max-width: 80%;
          max-height: 100%;
        }
      }
      .item-title {
        width: 50%;
        color: #333;
        font-size: 18px;
      }
      .item-price {
        width: 20%;
        text-align: center;
        font-size: 20px;
        color: red;
      }
    }
  }
  .submit-button {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: right;
    p {
      font-size: 20px;
      span {
        font-size: 26px;
        color: red;
      }
    }
    button {
      width: 100px;
      height: 50px;
      border-radius: 50px;
      border: 0;
      color: #fff;
      background-color: #409eff;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.wx-code {
  // background-color: #000;
  border-radius: 10px;
  text-align: center;
  .code-img {
    display: flex;
    justify-content: center;
  }
}
</style>
